
import { defineComponent } from "vue";
import loading from "@/assets/feed/loading1.webp";

export default defineComponent({
  name: "Loading",
  setup() {
    return {
      loading,
    };
  },
});
