
import { defineComponent, ref } from "vue";
import Navagation from "@/components/commons/Navagation.vue";
import Page from "@/components/commons/Page.vue";
import sort from "@/assets/nfts/sort.webp";
import Loading from "@/components/commons/Loading.vue";

import { useRoute } from "vue-router";
import { getItem } from "@/model/nftLanding";
import { getLongStringShow } from "@/utils/formatString";
import { displayDateFormat } from "@/utils/formatTime";
import {
  kmbNumber,
  getFloat,
  formatPercentageValue,
} from "@/utils/formatNumber";
import { getQueryString } from "@/utils/url";
import { modifyImgSize } from "@/utils/imgSize";
import { useLog } from "@/utils/log";
import { decompressFromBase64 } from "lz-string";

export default defineComponent({
  name: "ItemDetail",
  components: {
    Navagation,
    Page,
    Loading,
  },
  setup() {
    const state: any = ref({});
    const states: any = ref([]);
    const hadEnd = ref(false);
    const hadData = ref(false);
    const selectIndex = ref(0);
    const route = useRoute();
    const { handleClickLog } = useLog();

    let queryData = (route.query.data || "").toString();
    if (queryData) {
      queryData = queryData.replace(/\s/g, "+");
    }
    const decodeData = decompressFromBase64(queryData);
    const queryArray = decodeData.split("@");
    const contractAddr = ref(queryArray?.[0] || "");
    const pageLoadingShow = ref(true);
    const tokenIdsList = ref(queryArray?.[1]?.split("-") || []);
    const totalNumber = ref(
      tokenIdsList.value.length < 8 ? tokenIdsList.value.length : 8
    );

    const getTxsAccountWalletData = (first = false) => {
      pageLoadingShow.value = true;
      getItem({
        contractAddr: contractAddr.value,
        tokenId: tokenIdsList.value?.[selectIndex.value],
      }).then((res) => {
        pageLoadingShow.value = false;
        hadData.value = true;
        if (selectIndex.value === tokenIdsList.value.length - 1) {
          hadEnd.value = true;
        }
        if (res?.data && res?.code === 0) {
          states.value.push(res?.data);
          state.value = states.value[selectIndex.value];
        }
      });
    };
    getTxsAccountWalletData(true);
    const start = { x: 0, y: 0 };
    const end = { x: 0, y: 0 };
    const handleTouchstart = (e) => {
      start.x = e.touches[0].pageX;
      start.y = e.touches[0].pageY;
    };

    const handleTouchend = (e) => {
      end.x = e.changedTouches[0].pageX;
      end.y = e.changedTouches[0].pageY;
      if (
        Math.abs(end.x - start.x) <= Math.abs(end.y - start.y) ||
        Math.abs(end.x - start.x) < 20
      )
        return;
      if (start.x > end.x) {
        if (selectIndex.value < tokenIdsList.value.length - 1) {
          selectIndex.value += 1;
          if (selectIndex.value === states.value.length && !hadEnd.value) {
            getTxsAccountWalletData();
          } else {
            state.value = states.value[selectIndex.value];
          }
        }
      } else {
        if (selectIndex.value > 0) selectIndex.value -= 1;
        state.value = states.value[selectIndex.value];
      }
      if (
        tokenIdsList.value.length > 8 &&
        selectIndex.value > Math.floor(tokenIdsList.value.length / 8) * 8 - 1
      ) {
        totalNumber.value = tokenIdsList.value.length % 8;
      } else {
        totalNumber.value =
          tokenIdsList.value.length < 8 ? tokenIdsList.value.length : 8;
      }
    };
    const getHref = () => {
      return state.value?.belongToCollection?.id
        ? `/collection-detail/${state.value?.contract}?${getQueryString([
            "contract",
          ])}`
        : "javascript:;";
    };
    return {
      sort,
      state,
      states,
      hadData,
      getLongStringShow,
      kmbNumber,
      getFloat,
      formatPercentageValue,
      displayDateFormat,
      handleClickLog,
      getQueryString,
      modifyImgSize,
      getHref,
      selectIndex,
      totalNumber,
      handleTouchstart,
      handleTouchend,
      tokenIdsList,
      pageLoadingShow,
    };
  },
});
